import {
  useLDClient as useLaunchDarklyClient,
  useFlags as useLaunchDarklyFlags,
} from "launchdarkly-react-client-sdk";
import { createContext, useCallback } from "react";

import { report } from "src/services/error-reporting";

import { GET_EXPERIMENTS_CONTEXT } from "src/graphql/queries";

import { apolloClient } from "./ApolloContext";

export const FlagsContext = createContext();

export function FlagsProvider({ children }) {
  const client = useLaunchDarklyClient();

  // DO NOT SPREAD LD FLAGS,
  // i.e. { ...LaunchDarklyFlags }
  // flags object is a Proxy to evaluate a flag once prop is accessed,
  // spreading will trigger this evaluation for every prop
  const LaunchDarklyFlags = useLaunchDarklyFlags();

  const identify = useCallback(
    async (user) => {
      if (user?.id) {
        let userCtx = {
          kind: "user",
          key: user.id,
          email: user.email,
          anonymous: false,
        };
        try {
          const { data } = await apolloClient.query({
            query: GET_EXPERIMENTS_CONTEXT,
          });
          const ctxData = { ...data.experimentsContextData };
          delete ctxData.__typename; // gql generated type

          userCtx = { ...userCtx, ...ctxData };
        } catch (err) {
          console.error("Failed to fetch user context for LD", err);
          report(err);
        }
        client?.identify(userCtx);
      }
    },
    [client]
  );

  const track = useCallback(
    (key, data, metricValue) => {
      client.track(key, data, metricValue);
    },
    [client]
  );

  const flush = useCallback(
    (onDone) => {
      client.flush(onDone);
    },
    [client]
  );

  /**
   * Use this method to get evaluation `value` and `reason` for a flag.
   * The `reason` object contains information about the flag's evaluation details.
   * See https://docs.launchdarkly.com/sdk/concepts/evaluation-reasons#understanding-the-reason-data.
   *
   * If the context was evaluated as part of an experiment, the`inExperiment` prop will be set to `true`.
   *
   * Example:
   *
   * ```
   * const { value, reason } = evalWithDetails("test-experiment-blue-red-button");
   * if (reason.inExperiment) {
   *   console.log("User is in the experiment");
   *   telemetry.track("experiment-viewed", {
   *     value: value,
   *   });
   * }
   * ```
   *
   */
  const evalWithDetails = useCallback(
    (flagName) => {
      return client.variationDetail(flagName);
    },
    [client]
  );

  return (
    <FlagsContext.Provider
      value={{
        // DO NOT SPREAD LD FLAGS,
        // i.e. { ...LaunchDarklyFlags }
        // flags object is a Proxy to evaluate a flag once prop is accessed,
        // spreading will trigger this evaluation for every prop
        data: LaunchDarklyFlags,
        methods: {
          identify,
          track,
          flush,
          evalWithDetails,
        },
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
}
