import { useEffect, useRef, useState } from "react";

import useElement from "src/lib/layers/useElement";

import { useUser, useUserData } from "src/components/context/UserContext";

import { FlashTypes } from "src/components/elements/Flash";
import Text from "src/components/elements/Text";

import { FREE_TRIAL_MODAL_ELEMENT_ID } from "src/components/modals/FreeTrial";

import { useFlags, useFlagsMethods } from "src/hooks/useFlags";
import useFlash from "src/hooks/useFlash";
import useQueryParams from "src/hooks/useQueryParams";
import useTeams from "src/hooks/useTeams";

import Invite from "src/models/invite";
import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";
import { USER_TYPES } from "src/models/user";

import { createTeamsCheckoutSession } from "src/services/billing";
import { METRIC_EVENTS } from "src/services/feature-flags";
import {
  removeInviteUsersAfterFreeTrialPurchase,
  setInviteUsersAfterFreeTrialPurchase,
  setPendingInvites,
  setShouldTrackSignupCompleteAfterCheckout,
  setShowWelcomeSlides,
} from "src/services/storage";
import {
  OverlayInteractions,
  getExistingStandaloneSubscription,
  trackOverlayInteractedFreeTrial,
  trackTeamsFreeTrialRejected,
  trackTeamsFreeTrialSelected,
  trackTeamsSignupCompleted,
  trackTeamsSignupStarted,
  trackTeamsSignupStepCompletedV3,
} from "src/services/tracking";

import CarWithDog from "public/assets/img/car-with-dog.svg";
import IllustrationTeamsCreation2 from "public/assets/img/illustrations/teams-creation2.png";
import IllustrationTeamsCreation22x from "public/assets/img/illustrations/teams-creation2@2x.png";
import IllustrationTeamsCreation from "public/assets/img/illustrations/teams-creation.png";
import IllustrationTeamsCreation2x from "public/assets/img/illustrations/teams-creation@2x.png";

import CreateTeamForm from "./CreateTeamForm";
import SkuPicker from "./SkuPicker";

export default OnboardingPage;

function isProMonthSub(plan, type) {
  return (
    plan === TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO &&
    type === TEAM_SUBSCRIPTION_TYPES.MONTH
  );
}

function OnboardingPage() {
  const [ready, setReady] = useState(false);
  const [showSkuPicker, setShowSkuPicker] = useState(false);
  const [showRedirecting, setShowRedirecting] = useState(false);
  const [checkoutInProgress, setCheckoutInProgress] = useState(false);
  const me = useUser()?.user;
  const { team, refreshTeam } = useTeams(me);
  const { userData } = useUserData();
  const [flash, Flash] = useFlash();

  const { track: flagsTrack, flush: flagsFlush } = useFlagsMethods();
  const {
    miqDashboardTeamsOnboardingFreeTrialsWeb: freeTrialsInfo,
    miqDashboardTeamsSignupTestWeb,
  } = useFlags();

  const showIllustration = !["Test 1", "Test 2"].includes(
    miqDashboardTeamsSignupTestWeb
  );

  const {
    active: isFreeTrialsActive,
    coupon: productionCoupon,
    coupon_test: testCoupon,
    cta1: freeTrialBtnCta = "Start your 1-month free trial of Teams Pro",
    skipTrial:
      skipTrialCta = "No thanks, I prefer to pay full price for a different plan",
  } = freeTrialsInfo || {};
  const lastSubmittedNumOfSeats = useRef(1);

  const coupon =
    process.env.APP_ENV === "production" ? productionCoupon : testCoupon;
  const queryParams = useQueryParams();
  const plan =
    queryParams.get("planCode") || TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO;
  const type = queryParams.get("planType") || TEAM_SUBSCRIPTION_TYPES.MONTH;

  const freeTrialsModal = useElement(FREE_TRIAL_MODAL_ELEMENT_ID, {});

  const allowFreeTrials = isProMonthSub(plan, type) && isFreeTrialsActive;

  const canChangeSku = !queryParams.get("singleSku");

  async function handleSubmitTeam({ skipFreeTrial, numberOfSeats = 1 }) {
    lastSubmittedNumOfSeats.current = numberOfSeats;
    const team = await refreshTeam(true);
    setInviteUsersAfterFreeTrialPurchase(team.id);

    const trackingParams = {
      teamName: team.name,
      industry: team.industry,
      coupon,
    };

    const invites = [new Invite(me.email, USER_TYPES.ADMIN_DRIVER)];
    setPendingInvites(team.id, invites);

    if (allowFreeTrials) {
      if (skipFreeTrial) {
        trackTeamsFreeTrialRejected(trackingParams);
        setShowSkuPicker(true);
        return;
      } else {
        trackTeamsFreeTrialSelected(trackingParams);

        return freeTrialsModal.activate({
          props: {
            checkoutQty: numberOfSeats,
            onSubmit: () => {
              trackTeamsSignupStepCompletedV3({
                freeTrial: true,
                existingSaSubscription:
                  getExistingStandaloneSubscription(userData),
                industry: team.industry,
                companySize: team.size,
              });
            },
            onClose: () => {
              trackOverlayInteractedFreeTrial({
                overlayInteraction: OverlayInteractions.DISMISSED,
              });
              removeInviteUsersAfterFreeTrialPurchase(team.id);
              freeTrialsModal.deactivate();
            },
          },
        });
      }
    }

    checkout(team, plan, type, numberOfSeats);
  }

  async function checkout(team, plan, type, qty = 1) {
    if (checkoutInProgress) return;

    setCheckoutInProgress(true);

    trackTeamsSignupStepCompletedV3({
      existingSaSubscription: getExistingStandaloneSubscription(userData),
      industry: team.industry,
      companySize: team.size,
    });

    try {
      const res = await createTeamsCheckoutSession(
        plan,
        type,
        qty,
        window.location.href
      );
      if (res && res.session_url) {
        setShowWelcomeSlides(null, plan);
        setShouldTrackSignupCompleteAfterCheckout(team?.id);

        // go to stripe checkout
        window.location = res.session_url;
        return;
      }
    } catch (e) {
      flash(<Text>Something went wrong. Please try again.</Text>, {
        type: FlashTypes.ERROR,
      });
      console.error(e);
    }
    setCheckoutInProgress(false);
  }

  function handleRedirect(timeout = 0, url = "/") {
    // this is for Tinuiti TV ads pixel
    setTimeout(() => {
      if (team) {
        trackTeamsSignupCompleted({
          plan: team?.subscription?.plan,
          seatsAddedCount: team.subscription?.numberOfSeats || 0,
          freeTrial: team.subscription?.isFreeTrialActive,
        });
      }
      // this is for Tinuiti TV ads pixel
      setTimeout(() => {
        flagsTrack(METRIC_EVENTS.SIGNUP_COMPLETED);
        flagsFlush(() => {
          setTimeout(() => {
            window.location.href = url;
          }, timeout);
        });
      }, 200);
    }, 200);
  }

  function handleRedirecting(
    url = "/",
    title = "Loading",
    subtitle = "One second while we get you set up...",
    timeout = 2000
  ) {
    setShowRedirecting({ url, title, subtitle });
    handleRedirect(timeout, url);
  }

  function handleSkuSelected(sub = { plan, type }) {
    checkout(team, sub.plan, sub.type, lastSubmittedNumOfSeats.current);
  }

  useEffect(() => {
    const checkoutStatus =
      queryParams.get("checkout-status") || queryParams.get("checkout_status");
    const isCheckoutStatusSuccess = checkoutStatus === "success";
    const isCheckoutStatusCancel = checkoutStatus === "cancel";

    if (!checkoutStatus) {
      trackTeamsSignupStarted();
    }

    if (isCheckoutStatusSuccess || isCheckoutStatusCancel) {
      handleRedirecting("/teams", "Welcome to MileIQ for Teams!");
      return;
    }

    setReady(true);
  }, []);

  let btnTitle = "Continue to payment";

  if (allowFreeTrials) {
    btnTitle = freeTrialBtnCta;
  }

  if (showSkuPicker) {
    return (
      <Shell>
        <div className="pt-[80px] px-[30px] tablet:px-0">
          <SkuPicker
            team={team}
            plan={plan}
            type={type}
            onSubmit={handleSkuSelected}
            onBack={() => setShowSkuPicker(false)}
            checkoutInProgress={checkoutInProgress}
          />
        </div>
      </Shell>
    );
  }

  if (showRedirecting)
    return (
      <Shell>
        <div className="text-center flex flex-col items-center max-w-[540px] m-auto h-full justify-center">
          <h2 className="mt-6 whitespace-pre-line">{showRedirecting.title}</h2>
          <Text paragraph className="mt-3 text-black/70">
            {showRedirecting.subtitle}
          </Text>
          <div className="mt-14">
            <CarWithDog />
          </div>
        </div>
      </Shell>
    );

  if (!ready) return null; // prevent flashing of the form before redirecting after checkout

  return (
    <Shell className="bg-[#D6F2FF] mobile:bg-white">
      {showIllustration && (
        <>
          <img
            src={IllustrationTeamsCreation}
            srcSet={`${IllustrationTeamsCreation} 1244w, ${IllustrationTeamsCreation2x} 2488w`}
            sizes="(max-width: 1321px) 1244px, 2488px"
            alt="illustration"
            className="w-[270px] tablet:hidden absolute left-1/2 top-1/2 -translate-x-[650px] translate-y-[50px] z-10"
          />
          <img
            src={IllustrationTeamsCreation2}
            srcSet={`${IllustrationTeamsCreation2} 1244w, ${IllustrationTeamsCreation22x} 2488w`}
            sizes="(max-width: 1321px) 1244px, 2488px"
            alt="illustration"
            className="w-[325px] tablet:hidden absolute right-0 top-1/2 translate-x-1/5 translate-y-[155px] z-10"
          />
        </>
      )}
      <div className="relative z-20">
        <CreateTeamForm
          team={team}
          onSubmit={handleSubmitTeam}
          btnTitle={btnTitle}
          isFreeTrialsActive={allowFreeTrials}
          skipTrialCta={skipTrialCta}
          checkoutInProgress={checkoutInProgress}
          canChangeSku={canChangeSku}
        />
      </div>
      {Flash}
    </Shell>
  );
}

function Shell({ className = "", children }) {
  const cls =
    "page-onboarding page-onboarding-teams w-full relative flex items-center mobile:items-start justify-center " +
    className;
  return (
    <div className={cls}>
      <a
        className="logo-with-text logo--teams absolute left-[30px] top-[20px] mobile:left-[20px] mobile:top-[20px] z-[100]"
        href="https://mileiq.com/for-business"
        target="_blank"
        rel="noopener"
      />
      {children}
    </div>
  );
}
